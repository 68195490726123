<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Auditoria - Listar</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>
         

                <b-col sm="12" md="3"></b-col>

                <b-col sm="12" md="2">
                  <b-form-group label="Desde :">
                    <b-form-input @change="ListAudit" class="text-center" :max="to" type="date"  ref="to" v-model="from"></b-form-input>
                    <small v-if="errors.from" class="form-text text-danger" >Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="2">
                  <b-form-group label="Hasta :">
                    <b-form-input @change="ListAudit" class="text-center" :min="from" type="date"  ref="from" v-model="to"></b-form-input>
                    <small v-if="errors.to" class="form-text text-danger" >Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                
                <b-col sm="6" md="2">
                  <b-form-group label=".">
                    <b-input-group>
                    <b-form-input @keyup.enter="ListAudit" v-model="search" class="form-control"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" @click="ListAudit"><b-icon icon="search"></b-icon></b-button>
                    </b-input-group-append>
                  </b-input-group>
                  </b-form-group>
                </b-col>

             
            </b-row>

            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="13%" class="text-center">Fecha</th>
                    <th width="25%" class="text-center">Usuario</th>
                    <th width="20%" class="text-center">Modulo</th>
                    <th width="30%" class="text-center">Descripción</th>
                    <th width="7%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.date  }}</td>
                    <td class="text-left"> {{ item.user  }}</td>
                    <td class="text-left"> {{ item.module  }}</td>
                    <td class="text-left"> {{ item.description  }}</td>
                  
                 
                    <td class="text-center">
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item @click="ViewAudit(item.id)" >Ver</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage" v-on:input="ListAudit" :total-rows="rows" :per-page="perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>


            <b-modal size="md" class="modal-size" hide-footer v-model="modal_view" title="VER DETALLE DE AUDITORIA">
              <b-form>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Usuario:">
                      <b-form-input readonly v-model="audit.user"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Fecha:">
                      <b-form-input readonly v-model="audit.date"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Modulo:">
                      <b-form-input readonly v-model="audit.module"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Descripción:">
                      <b-form-input readonly v-model="audit.description"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Valor Anterior:">
                      <pre>{{ audit.old_value }}</pre>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Valor Nuevo:">
                      <pre>{{ audit.new_value }}</pre>
                    </b-form-group>
                  </b-col>
                </b-row>
                </b-form>
            </b-modal>


          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<style>
pre {
    max-width: 100%;
    /* Puedes ajustar otros estilos según tus preferencias */
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    overflow: auto; /* Agrega barras de desplazamiento si el contenido es demasiado ancho */
}
</style>
<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
export default {
  name: "UsuarioList",
  components:{
    vSelect,
  },
  data() {
    return {
      module:'Audit',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      data_table: [],
      
      id_partner:'',
      to:moment(new Date()).local().format("YYYY-MM-DD"),
      from:moment().subtract(30, 'days').local().format("YYYY-MM-DD"),
      search: "",
      partners: [],
      partner:null,
      errors:{
        to:false,
        from:false,
      },

      audit:{
        date:'',
        module:'',
        description:'',
        old_value:'',
        new_value:'',
        user:'',
      },
      modal_view:false,
    };
  },
  mounted() {
    this.ListAudit();
  },
  methods: {
    ListAudit,
    ViewAudit,
    Permission,
    SearchUser,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};



function SearchUser(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-partner/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.partners = response.data;
            loading(false);
      })
    }
}

function ListAudit() {
  this.errors.from = false;
  this.errors.to = false;

  let id_partner = this.partner == null ? 'all' : this.partner.id;
  if (this.from.length == 0) {this.errors.from = true; return false;}
  if (this.to.length == 0) {this.errors.to = true; return false;}
  let search = this.search == "" ? "all" : this.search;

  let me = this;
  let url = this.url_base + "audit/list/" + id_partner + "/" + this.from + "/" + this.to + "/" + search + "?page=" + this.currentPage;

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}


// Ver Usuario
function ViewAudit(id_audit) {
  let me = this;
  let url = this.url_base + "audit/view/" + id_audit;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.audit = response.data.result;
        me.audit.old_value = JSON.parse(response.data.result.old_value);
        me.audit.new_value = JSON.parse(response.data.result.new_value);
        me.modal_view = true;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
